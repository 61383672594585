import * as React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import {Link} from "gatsby";

const NotFoundPage = () => {
    return (
        <Layout>
            <Navbar/>

            <div id="formContent" className={'container'}>
                <div className="section-title">
                    <h1>Cardizoo</h1>
                    <p>
                        for a hearty life
                    </p>
                </div>

                <h2>Your Page couldn´t be found</h2>
                <Link
                    to="/"
                    className="fadeIn second"
                >Back to Home</Link>
            </div>
            <Footer />
        </Layout>
    )
}

export default NotFoundPage
